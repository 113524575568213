<template>
    <div id="AdvancedCourseList">
        <p class="f14 mb-f12 course-intro-style">
            真理学修をさせて頂いた後は「親理総合理学修の理」を頂いて、理解と真実感を更に深めましょう。
            <br />
            <br />
            <router-link
                v-if="serverRole === 'user'"
                :to="{ name: 'CourseForm' }"
                class="text-link-style"
            >
                <i class="icon-page-link f12 f12-mb"></i>
                「親理総合理学修の理」申し込み
            </router-link>
        </p>
        <ul class="course-list-style f20">
            <li v-for="(item, index) in theologyList" :key="item.subCategory">
                <router-link
                    v-if="
                        serverRole === 'admin' ||
                        index < 2 ||
                        theologyList[index - 1].status
                    "
                    :to="{
                        name: advancedCourseRouteName,
                        params: { subCategory: item.subCategory },
                    }"
                    >{{ item.subCategory }}</router-link
                >
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'AdvancedCourseList',
}
</script>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const serverRole = ref(store.getters.getServerRole)
const theologyList = ref(store.getters.getTheologySubcategory)
const advancedCourseRouteName = computed(() => {
    if (serverRole.value === 'user') return 'AdvancedCourseVideos'
    else if (serverRole.value === 'admin') return 'AdminAdvancedCourseVideos'
    else return 'Home'
})
</script>
<style lang="scss" scoped></style>
